import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/app/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private apiUrl = environment.apiUrl; // Replace with your backend URL

  constructor(private http: HttpClient) {}

  login(username: string, password: string): Observable<any> {
    const body = { username, password };
    return this.http.post<any>(this.apiUrl, body);
  }
  loginweb(postdata: any) {
    return this.http.post(
      environment.apiUrl + '/webuserlogin/webuserlogin',
      postdata
    );
  }
  loginwebSelectCompany(postdata: any) {
    return this.http.post(
      environment.apiUrl + '/logincompany/selectcompany',
      postdata
    );
  }
  logout(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('userData'); // Clear user data
  }
  setToken(token: string): void {
    localStorage.setItem('token', token);
  }
  getCompanyInfo(): any | null {
    const data = localStorage.getItem('cmpinfo');
    return data ? JSON.parse(data) : null;
  }
  setCompanyInfo(cmpinfo: any): void {
    localStorage.setItem('cmpinfo', JSON.stringify(cmpinfo));
  }
  getToken(): string | null {
    return localStorage.getItem('token');
  }
  setUserData(userData: any): void {
    localStorage.setItem('userData', JSON.stringify(userData));
  }
  getUserData(): any | null {
    const data = localStorage.getItem('userData');
    return data ? JSON.parse(data) : null;
  }

  isLoggedIn(): boolean {
    return this.getToken() !== null;
  }
  check() {
    return this.getToken() ? true : false;
  }
}
