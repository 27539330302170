export const environment = {
  production: false,
  baseUrl: '',
  useHash: true,
  apiUrl: 'https://abrasive.shivshaktisoftware.com/server/api',
  onlyUrl: 'https://abrasive.shivshaktisoftware.com/server/',
  authApi: ''
};
// apiUrl: 'https://abrasive.shivshaktisoftware.com/server/api',
// onlyUrl: 'https://abrasive.shivshaktisoftware.com/server/',
// apiUrl: 'http://localhost:3001/api',
// onlyUrl: 'http://localhost:3001/',
