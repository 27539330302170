import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { catchError, throwError } from 'rxjs';

export enum STATUS {
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  IFORMATION = 400,
  INTERNAL_SERVER_ERROR = 500
}

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private readonly router = inject(Router);
  private readonly toast = inject(ToastrService);
  private readonly errorPages = [
    STATUS.FORBIDDEN,
    STATUS.NOT_FOUND,
    STATUS.IFORMATION,
    STATUS.INTERNAL_SERVER_ERROR
  ];

  private getMessage = (error: HttpErrorResponse) => {
    if (error.error?.message) {
      return error.error.message;
    }

    if (error.error?.msg) {
      return error.error.msg;
    }

    return `${error.status} ${error.statusText}`;
  };

  intercept(request: HttpRequest<unknown>, next: HttpHandler) {
    return next
      .handle(request)
      .pipe(catchError((error: HttpErrorResponse) => this.handleError(error)));
  }

  private handleError(error: HttpErrorResponse) {
    if (this.errorPages.includes(error.status)) {
      if (error.error.msg) {
        if (error.status == 400) {
          this.toast.info(error.error.msg);
        } else {
          this.toast.error(error.error.msg);
        }
      } else if (error.error.err.sqlMessage) {
        this.toast.error(error.error.err.sqlMessage);
      } else if (error.error.err) {
        this.toast.error(error.error.err);
      } else {
        this.toast.error(error.error);
      }
      // this.router.navigateByUrl(`/${error.status}`, {
      //   skipLocationChange: true,
      // });
    } else {
      if (error.status === STATUS.UNAUTHORIZED) {
        this.router.navigateByUrl('/login');
      }
    }

    return throwError(() => error);
  }
}
