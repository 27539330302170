export interface User {
  [prop: string]: any;
  id?: number | string | null;
  name?: string;
  email?: string;
  avatar?: string;
  roles?: string;
  permissions?: any[];
  apitoken?:string;
  cid?:number |string|null;
  clentid?:number |string|null;
  yrid?:number |string|null;

}

export interface Token {
  [prop: string]: any;

  access_token: string;
  token_type?: string;
  expires_in?: number;
  exp?: number;
  refresh_token?: string;
}

// export interface User {
//   [prop: string]: any;

//   id?: number | string | null;
//   name?: string;
//   email?: string;
//   avatar?: string;
//   roles?: any[];
//   permissions?: any[];
// }

// export interface Token {
//   [prop: string]: any;

//   access_token: string;
//   token_type?: string;
//   expires_in?: number;
//   exp?: number;
//   refresh_token?: string;
// }
